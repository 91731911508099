import { store } from "../redux/store";
//default lang, map api key, firebase config, theme settings default color dark or light,
// base URL of your admin panel

export const REACT_APP_NAME="RatedDash"


export const REACT_APP_BASE_URL = "https://dashboard.ratedartisan.ai/api/v1/";



export const REACT_APP_FIREBASE_API_KEY = "AIzaSyB7FEOwhZqQTgyyCLI1HEOewfQA27vx5u8";
export const REACT_APP_FIREBASE_AUTH_DOMAIN = "ratedartisan-5e1bd.firebaseapp.com";
export const REACT_APP_FIREBASE_PROJECT_ID = "ratedartisan-5e1bd";
export const REACT_APP_FIREBASE_STORAGE_BUCKET = "ratedartisan-5e1bd.appspot.com";
export const REACT_APP_FIREBASE_MESSAGEING_SENDER_ID = "455238264403";
export const REACT_APP_FIREBASE_APP_ID = "1:455238264403:web:90f48fe3f9d3fbd27908a6";
export const REACT_APP_FIREBASE_MESUMENT_ID = "G-QCY8WEBQ0M";

export const REACT_APP_FIREBASE_VAPID_KEY = "BG0bXd-BJTm_rkgApQFMcyBOQVDNIvoUsBklAeRBJMnXjc2vXu-AEZ-s-81KXPT_B537SvN065vnusJAHNTHuzI";


export const MAP_API = process.env.REACT_APP_MAP_API;

export const GOOGLE_MAP = "https://maps.googleapis.com/maps/api/js";

let config = {
  supportedLanguages: ["en", "de", "es"],
  defaultLanguage: "en",
};
export default config;

export const DECIMAL_POINT = () => {
  const state = store.getState();
  return state.Settings?.settings?.general_settings?.decimal_point;
};

/** Only modify above given config
 * Do not touch below code
 * */
// all possible order statuses
export const order_statues = [
  "awaiting",
  "confirmed",
  "cancelled",
  "rescheduled",
  "completed",
  "started",
];

//function for load google map
export function loadAsyncScript(src) {
  return new Promise((resolve) => {
    // Check if the script with the given source already exists
    const existingScript = document.querySelector(`script[src="${src}"]`);
    if (existingScript) {
      // If the script already exists, resolve the promise immediately
      resolve(existingScript);
    } else {
      // Otherwise, create a new script element and load the script
      const script = document.createElement("script");
      Object.assign(script, {
        type: "text/javascript",
        async: true,
        src,
      });
      script.addEventListener("load", () => resolve(script));
      document.head.appendChild(script);
    }
  });
}

// Function to handle open drawer
export const handleOpen = (setDrawerOpen) => {
  setDrawerOpen(true);
}

// Function to handle close drawer
export function handleClose(setDrawerOpen) {
  setDrawerOpen(false);
}
